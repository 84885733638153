/* body {
    font-family: 'Roboto', sans-serif;
    background-color: #f5f5f5;

} */


.wrapper {
    display: flex;
}

.Card {
    margin-bottom: 20px;
    width: 100%;
}

/* .wrapper > .Card:first-of-type {
    margin-right: 20px;
} */

.notified--card {
    display: flex;
    align-items: center;
}

.notified--card .left {
    margin-left: auto;
    margin-right: 10px;
}

.notified--card .right {
    width: 50%;
}

.notified--card .right > div {
    display: flex;
    align-items: center;
}

.time-pickers {
    padding: 30px !important;
}

.time-pickers > div {
    margin-left: 150px;
}

.contact--card {
    display: flex;
}

.contact--card > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 30px;
    width: 100%;
}

.contact--card .FormControl {
    width: 100%;
}

.contact--card .FormControl label + .MuiInput-formControl {
    margin-top: 25px;
}

.number-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.number-wrapper .Card {
    width: 290px;
}
.number-wrapper .MuiCollapse-wrapperInner {
    display: flex;
}
.number-wrapper .MuiCollapse-wrapperInner .Card:nth-child(odd) {
    margin-right: 20px;
}

.number-wrapper .Card .MuiFormControlLabel-label {
    font-size: 14px;
}
.btn-default {
    margin-right: 10px !important;
}

.MuiTableContainer-root {
    margin-bottom: 20px;
}

/* .notification_textBox {
    margin: 10px 20px !important;
} */

.notification_label-wrapper {
    height: 50px;
}

.notification_label {
    width: 75px;
    text-align: left;
}

.clock-label {
    margin-top: 20px !important;
    margin-right: 20px !important;
    width: 100px;
}

#demo-simple-select-label {
    font-size: 14px;
}
