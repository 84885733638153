.root-modal {
  display: block !important;
}

.root-modal > .root-modal-body.background {
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.4s ease-in-out;
}

.body {
  padding: 10px;
}

textarea.form-control.feedback {
  min-height: 200px;
  overflow-y: hidden;
}

.mstrue {
  opacity: 1;
  pointer-events: showing;
}

.ReactModal__Overlay.ReactModal__Overlay--after-open.modal-overlay {
  z-index: 9999;
}

.modal-content {
  position: fixed;
  top: 50%;
  left: 50%;
  min-height: 350px;
  width: 70vw;
  transform: translate(-50%, -50%);
  background: white;
  overflow-y: auto;
  border-radius: 0;
  outline: none;
  border: 0 solid inherit;
  box-shadow: inherit;
}

.status-bar {
  position: relative;
  bottom: 0;
  left: 0;
}

.btn.exit {
  background-color: transparent;
  width: 15px;
  height: 15px;
  color: white;
}

.exit {
  color: red;
  background-color: white;
  font-size: 15px;
}

.footer {
  /* position: absolute;
    bottom: 0;
    right: 0;
    left: 0; */
  padding: 10px;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
}

.dc-r-flex-container {
  display: flex !important;
  justify-content: space-between !important;
}

.dc-r-flex-container-end {
  display: flex !important;
  justify-content: flex-end !important;
}

.dc-r-flex-container-start {
  display: flex !important;
  justify-content: flex-start !important;
}

.dc-r-flex-container-end > .item {
}

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 0.4s ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

.btn {
  background-color: transparent;
  border-radius: 0;
  border-color: transparent;
  color: gray;
  font-size: small;
}

.root-modal {
  display: block !important;
}

.root-modal > .root-modal-body.background {
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.4s ease-in-out;
}

.body {
  padding: 10px;
}

textarea.form-control.feedback {
  min-height: 200px;
  overflow-y: hidden;
}

.mstrue {
  opacity: 1;
  pointer-events: showing;
}

.ReactModal__Overlay.ReactModal__Overlay--after-open.modal-overlay {
  z-index: 9999;
}

.modal-content {
  position: fixed;
  top: 50%;
  left: 50%;
  min-height: 350px;
  width: 70vw;
  transform: translate(-50%, -50%);
  background: white;
  overflow-y: auto;
  border-radius: 0;
  outline: none;
  border: 0 solid inherit;
  box-shadow: inherit;
}

.status-bar {
  position: relative;
  bottom: 0;
  left: 0;
}

.btn.exit {
  background-color: transparent;
  width: 15px;
  height: 15px;
  color: white;
}

.exit {
  color: red;
  background-color: white;
  font-size: 15px;
}

.footer {
  /* position: absolute;
      bottom: 0;
      right: 0;
      left: 0; */
  padding: 10px;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
}

.dc-r-flex-container {
  display: flex !important;
  justify-content: space-between !important;
}

.dc-r-flex-container-end {
  display: flex !important;
  justify-content: flex-end !important;
}

.dc-r-flex-container-start {
  display: flex !important;
  justify-content: flex-start !important;
}

.dc-r-flex-container-end > .item {
}

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 0.4s ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

.responseScroller {
  width: 300px;
  height: 100px;
  overflow-y: scroll;
  scrollbar-width: thin;
}

.btn {
  background-color: transparent;
  border-radius: 0;
  border-color: transparent;
  color: gray;
  font-size: small;
}
.responseDropdown {
  left: 20;
  width: auto;
  z-index: 2;
  border: 1px solid rgba(0, 0, 0, 0.04);
  box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.14);
}

.responseButton {
  position: relative;
  display: inline-block;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
a {
  padding: 8px 12px;
}

li:hover {
  background-color: rgba(0, 0, 0, 0.14);
  cursor: pointer;
}
