.__flex_container_dcb {
    display: flex;
    max-width: 97vw;
    flex-direction: row;
    justify-content: space-between;
}
.__flex_item_dcb{
    padding: 10px;
    
}

.__flex {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.__flex > .__item {
    padding: 10px;
}

.table.review.large.screen {
    display: inline-table;
    border: 1;
    width: 95vw;
    margin: 0px 10px;
}

.table.review.small.screen {
    display: none;
}

@media only screen and (max-width: 768px) {
    /* For mobile phones: */

    .table.review.large.screen {
        display: none;
    }

    .table.review.small.screen {
        display: inline-table;
    }

    /* .table.review.small.screen > tfoot > tr > td > div.__flex.__footer {
        flex-direction: column;
        justify-items: center;
    } */

    .table.review.small.screen > tfoot > tr > td > div.__flex.__footer {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    
    .table-review-content > tbody > tr > td {
        border: 0;
    }
}