.platform-container {
    /* min-height: 60vh; */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.platform-container-text {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: gray;
}

.platform-container-assignment-group {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-top: '20px'
}
.platform-container-jira-priority {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}