.__paginator_item {
    padding: 0px 7px;
}

.__active_page_link {
    text-decoration: none;
    color: white;
}

.__paginator_item.active:hover > .__active_page_link,
.__paginator_item.active > .__active_page_link:hover {
    background-color: white;
    color: #007bff;
}

.__paginator_item.active > .__active_page_link {
    color: white;
    background-color: #007bff;
}
/* .__active_page_link:hover{
    color: #007bff;
    border-color: #007bff;
} */

.__paginator_item {
    text-decoration: none;
}

.__paginator_item > a:hover {
    text-decoration: none;
}

.active{
    text-decoration: none;
    background-color: #007bff;
    border-radius: 50%;
    color: white;
}

.active:hover {
    text-decoration: none;
    background-color: white;
    color: #007bff;
    border-radius: 50%;
    border-color: #007bff;
}

.__active_page_link:hover {
    text-decoration: none;
    background-color: white;
    color: #007bff;
    border-color: #007bff;
}


@media only screen and (max-width: 768px) {
    /* For mobile phones: */
    
}