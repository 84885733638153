.selected {
    color: green;
    width: 5px;
    height: 5px;
}

.platform-item-placeholder {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.platform-item-placeholder > img {
    width: 200px;
    height: 70px;
}

.platform-item-placeholder > .item {
    padding: 10px;
}

img.slack {
    height: 45px;
    width: 130px;
}

.item-container-column {
    display: flex;
    flex-direction: column;
}

.item-container-row {
    display: flex;
    flex-direction: row;
}