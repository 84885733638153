body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#collasible-nav-dropdown-automatedcallingsystem {
    background: white;
    color:black;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-float infinite 3s ease-in-out;
            animation: App-logo-float infinite 3s ease-in-out;
  }
}

.App-header {
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: rgb(112, 76, 182);
}

@-webkit-keyframes App-logo-float {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  50% {
    -webkit-transform: translateY(10px);
            transform: translateY(10px)
  }
  100% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px)
  }
}

@keyframes App-logo-float {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  50% {
    -webkit-transform: translateY(10px);
            transform: translateY(10px)
  }
  100% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px)
  }
}

.landing__page {
    display:-webkit-flex;
    display:flex;
    min-height: 83vh;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
}

.greeting__user__label {
    text-transform: capitalize;
}
.basic__footer {
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: flex-end;
            align-items: flex-end;
}

.basic__footer > p {
    text-align: center;
    color: gray;
    font-size: small;
}
.daterangepicker .ranges li.active {
    background-color: #08c;
    color: #fff;
    border-radius: 0%;
}

.daterangepicker.ltr.show-ranges.opensright.show-calendar {
    z-index: 9999;
}
.__flex_container {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
}

.__dropdown_index {
    z-index: 0;
}

.__flex_container > .__flex_item {
    font-size: small;
    margin-top: 10px; 
    margin-bottom: 10px;
    padding: 0 10px 0 10px;
}

.__flex_container > .__flex_item.__selector {
    min-width: 200px;
    cursor: pointer;
}

.__flex_item.__selector:hover {
    cursor: pointer;
}

.__flex_header_container {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: space-between;
            justify-content: space-between;
}

.__flex_header_container > .__flex_item {
    /* font-size: small; */
    margin-top: 10px; 
    margin-bottom: 10px;
    padding: 0 10px 0 10px;
}

@media only screen and (min-width: 600px) {
    /* For tablets: */
    .__flex_header_container {
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: row;
                flex-direction: row;
        -webkit-justify-content: space-between;
                justify-content: space-between;
    }
    
    .__flex_header_container > .__flex_item {
        /* font-size: small; */
        margin-top: 10px; 
        margin-bottom: 10px;
        padding: 0 10px 0 10px;
    }

    .__flex_container {
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: column;
                flex-direction: column;
        -webkit-align-items: center;
                align-items: center;
    }
    
    .__flex_container > .__flex_item {
        font-size: small;
        margin-top: 10px; 
        margin-bottom: 10px;
        padding: 0 10px 0 10px;
    }
    
    .__flex_container > .__flex_item.__selector {
        min-width: 200px;
    }
}

@media only screen and (min-width: 768px) {
    /* For desktop: */

    .__flex_header_container {
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: row;
                flex-direction: row;
        -webkit-justify-content: space-between;
                justify-content: space-between;
    }
    
    .__flex_header_container > .__flex_item {
        /* font-size: small; */
        margin-top: 10px; 
        margin-bottom: 10px;
        padding: 0 10px 0 10px;
    }

.__flex_container {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: space-between;
            justify-content: space-between;
}

.__flex_container > .__flex_item {
    font-size: small;
    margin-top: 10px; 
    margin-bottom: 10px;
    padding: 0 10px 0 10px;
}

.__flex_container > .__flex_item.__selector {
    min-width: 200px;
}

}
.__paginator_item {
    padding: 0px 7px;
}

.__active_page_link {
    text-decoration: none;
    color: white;
}

.__paginator_item.active:hover > .__active_page_link,
.__paginator_item.active > .__active_page_link:hover {
    background-color: white;
    color: #007bff;
}

.__paginator_item.active > .__active_page_link {
    color: white;
    background-color: #007bff;
}
/* .__active_page_link:hover{
    color: #007bff;
    border-color: #007bff;
} */

.__paginator_item {
    text-decoration: none;
}

.__paginator_item > a:hover {
    text-decoration: none;
}

.active{
    text-decoration: none;
    background-color: #007bff;
    border-radius: 50%;
    color: white;
}

.active:hover {
    text-decoration: none;
    background-color: white;
    color: #007bff;
    border-radius: 50%;
    border-color: #007bff;
}

.__active_page_link:hover {
    text-decoration: none;
    background-color: white;
    color: #007bff;
    border-color: #007bff;
}


@media only screen and (max-width: 768px) {
    /* For mobile phones: */
    
}
.__flex_container_dcb {
    display: -webkit-flex;
    display: flex;
    max-width: 97vw;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: space-between;
            justify-content: space-between;
}
.__flex_item_dcb{
    padding: 10px;
    
}

.__flex {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: space-between;
            justify-content: space-between;
}

.__flex > .__item {
    padding: 10px;
}

.table.review.large.screen {
    display: inline-table;
    border: 1;
    width: 95vw;
    margin: 0px 10px;
}

.table.review.small.screen {
    display: none;
}

@media only screen and (max-width: 768px) {
    /* For mobile phones: */

    .table.review.large.screen {
        display: none;
    }

    .table.review.small.screen {
        display: inline-table;
    }

    /* .table.review.small.screen > tfoot > tr > td > div.__flex.__footer {
        flex-direction: column;
        justify-items: center;
    } */

    .table.review.small.screen > tfoot > tr > td > div.__flex.__footer {
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: column;
                flex-direction: column;
        -webkit-justify-content: center;
                justify-content: center;
        -webkit-align-items: center;
                align-items: center;
    }
    
    .table-review-content > tbody > tr > td {
        border: 0;
    }
}
.root-modal {
  display: block !important;
}

.root-modal > .root-modal-body.background {
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.4s ease-in-out;
}

.body {
  padding: 10px;
}

textarea.form-control.feedback {
  min-height: 300px;
  overflow-y: hidden;
}

.mstrue {
  opacity: 1;
  pointer-events: showing;
}

.ReactModal__Overlay.ReactModal__Overlay--after-open.modal-overlay {
  z-index: 9999;
}

.modal-content {
  position: fixed;
  top: 50%;
  left: 50%;
  min-height: 450px;
  width: 70vw;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  background: white;
  overflow-y: auto;
  border-radius: 0;
  outline: none;
  border: 0 solid inherit;
  box-shadow: inherit;
}

.status-bar {
  position: relative;
  bottom: 0;
  left: 0;
}

.btn.exit {
  background-color: transparent;
  width: 15px;
  height: 15px;
  color: white;
}

.exit {
  color: red;
  background-color: white;
  font-size: 15px;
}

.footer {
  /* position: absolute;
    bottom: 0;
    right: 0;
    left: 0; */
  padding: 10px;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
}

.dc-r-flex-container {
  display: -webkit-flex !important;
  display: flex !important;
  -webkit-justify-content: space-between !important;
          justify-content: space-between !important;
}

.dc-r-flex-container-end {
  display: -webkit-flex !important;
  display: flex !important;
  -webkit-justify-content: flex-end !important;
          justify-content: flex-end !important;
}

.dc-r-flex-container-start {
  display: -webkit-flex !important;
  display: flex !important;
  -webkit-justify-content: flex-start !important;
          justify-content: flex-start !important;
}

.dc-r-flex-container-end > .item {
}

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 0.4s ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

.btn {
  background-color: transparent;
  border-radius: 0;
  border-color: transparent;
  color: gray;
  font-size: small;
}

.root-modal {
  display: block !important;
}

.root-modal > .root-modal-body.background {
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.4s ease-in-out;
}

.body {
  padding: 10px;
}

textarea.form-control.feedback {
  min-height: 300px;
  overflow-y: hidden;
}

.mstrue {
  opacity: 1;
  pointer-events: showing;
}

.ReactModal__Overlay.ReactModal__Overlay--after-open.modal-overlay {
  z-index: 9999;
}

.modal-content {
  position: fixed;
  top: 50%;
  left: 50%;
  min-height: 450px;
  width: 70vw;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  background: white;
  overflow-y: auto;
  border-radius: 0;
  outline: none;
  border: 0 solid inherit;
  box-shadow: inherit;
}

.status-bar {
  position: relative;
  bottom: 0;
  left: 0;
}

.btn.exit {
  background-color: transparent;
  width: 15px;
  height: 15px;
  color: white;
}

.exit {
  color: red;
  background-color: white;
  font-size: 15px;
}

.footer {
  /* position: absolute;
      bottom: 0;
      right: 0;
      left: 0; */
  padding: 10px;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
}

.dc-r-flex-container {
  display: -webkit-flex !important;
  display: flex !important;
  -webkit-justify-content: space-between !important;
          justify-content: space-between !important;
}

.dc-r-flex-container-end {
  display: -webkit-flex !important;
  display: flex !important;
  -webkit-justify-content: flex-end !important;
          justify-content: flex-end !important;
}

.dc-r-flex-container-start {
  display: -webkit-flex !important;
  display: flex !important;
  -webkit-justify-content: flex-start !important;
          justify-content: flex-start !important;
}

.dc-r-flex-container-end > .item {
}

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 0.4s ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

.responseScroller {
  width: 300px;
  height: 100px;
  overflow-y: scroll;
  scrollbar-width: thin;
}

.btn {
  background-color: transparent;
  border-radius: 0;
  border-color: transparent;
  color: gray;
  font-size: small;
}
.responseDropdown {
  left: 20;
  width: auto;
  z-index: 2;
  border: 1px solid rgba(0, 0, 0, 0.04);
  box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.14);
}

.responseButton {
  position: relative;
  display: inline-block;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
a {
  padding: 8px 12px;
}

li:hover {
  background-color: rgba(0, 0, 0, 0.14);
  cursor: pointer;
}

.spinner {
    width: 15px;
    height: 15px;
    margin: auto;
  
    position: relative;
    margin: auto;
  }
  
  .double-bounce1, .double-bounce2 {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: lightgreen;
    opacity: 0.6;
    position: absolute;
    top: 0;
    left: 0;
    
    -webkit-animation: sk-bounce 2.0s infinite ease-in-out;
    animation: sk-bounce 2.0s infinite ease-in-out;
  }
  
  .double-bounce2 {
    -webkit-animation-delay: -1.0s;
    animation-delay: -1.0s;
  }
  
  @-webkit-keyframes sk-bounce {
    0%, 100% { -webkit-transform: scale(0.0) }
    50% { -webkit-transform: scale(1.0) }
  }
  
  @keyframes sk-bounce {
    0%, 100% { 
      transform: scale(0.0);
      -webkit-transform: scale(0.0);
    } 50% { 
      transform: scale(1.0);
      -webkit-transform: scale(1.0);
    }
  }
body {
    /* font-family: "Montserrat", "Lato", "Open Sans", "Helvetica Neue", Helvetica, Calibri, Arial, sans-serif; */
    /* color: hsl(218, 9%, 46%);
    background: #f2f2f2; */
  }
  .jumbotron {
    background: #6b7381;
    color: #bdc1c8;
  }
  .jumbotron h1 {
    color: #fff;
  }
  .example {
    margin: 4rem auto;
  }
  .example > .row {
    margin-top: 2rem;
    height: 5rem;
    vertical-align: middle;
    text-align: center;
    border: 1px solid rgba(189, 193, 200, 0.5);
  }
  .example > .row:first-of-type {
    border: none;
    height: auto;
    text-align: left;
  }
  .example h3 {
    font-weight: 400;
  }
  .example h3 > small {
    font-weight: 200;
    font-size: 0.75em;
    color: #939aa5;
  }
  .example h6 {
    font-weight: 700;
    font-size: 0.65rem;
    letter-spacing: 3.32px;
    text-transform: uppercase;
    color: #bdc1c8;
    margin: 0;
    line-height: 5rem;
  }
  .example .btn-toggle {
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
  }
  .btn-toggle {
    margin: 0 4rem;
    padding: 0;
    position: relative;
    border: none;
    height: 1.5rem;
    width: 3rem;
    border-radius: 1.5rem;
    color: #6b7381;
    background: #bdc1c8;
  }
  .btn-toggle:focus,
  .btn-toggle.focus,
  .btn-toggle:focus.active,
  .btn-toggle.focus.active {
    outline: none;
  }
  .btn-toggle:before,
  .btn-toggle:after {
    line-height: 1.5rem;
    width: 4rem;
    text-align: center;
    font-weight: 600;
    font-size: 0.75rem;
    text-transform: uppercase;
    letter-spacing: 2px;
    position: absolute;
    bottom: 0;
    transition: opacity 0.25s;
  }
  .btn-toggle:before {
    content: "Off";
    left: -4rem;
  }
  .btn-toggle:after {
    content: "On";
    right: -4rem;
    opacity: 0.5;
  }
  .btn-toggle > .handle {
    position: absolute;
    top: 0.1875rem;
    left: 0.1875rem;
    width: 1.125rem;
    height: 1.125rem;
    border-radius: 1.125rem;
    background: #fff;
    transition: left 0.25s;
  }
  .btn-toggle.active {
    transition: background-color 0.25s;
  }
  .btn-toggle.active > .handle {
    left: 1.6875rem;
    transition: left 0.25s;
  }
  .btn-toggle.active:before {
    opacity: 0.5;
  }
  .btn-toggle.active:after {
    opacity: 1;
  }
  .btn-toggle.btn-sm:before,
  .btn-toggle.btn-sm:after {
    line-height: -0.5rem;
    color: #fff;
    letter-spacing: 0.75px;
    left: 0.4125rem;
    width: 2.325rem;
  }
  .btn-toggle.btn-sm:before {
    text-align: right;
  }
  .btn-toggle.btn-sm:after {
    text-align: left;
    opacity: 0;
  }
  .btn-toggle.btn-sm.active:before {
    opacity: 0;
  }
  .btn-toggle.btn-sm.active:after {
    opacity: 1;
  }
  .btn-toggle.btn-xs:before,
  .btn-toggle.btn-xs:after {
    display: none;
  }
  .btn-toggle:before,
  .btn-toggle:after {
    color: #6b7381;
  }
  .btn-toggle.active {
    background-color: #29b5a8;
  }
  .btn-toggle.btn-lg {
    margin: 0 5rem;
    padding: 0;
    position: relative;
    border: none;
    height: 2.5rem;
    width: 5rem;
    border-radius: 2.5rem;
  }
  .btn-toggle.btn-lg:focus,
  .btn-toggle.btn-lg.focus,
  .btn-toggle.btn-lg:focus.active,
  .btn-toggle.btn-lg.focus.active {
    outline: none;
  }
  .btn-toggle.btn-lg:before,
  .btn-toggle.btn-lg:after {
    line-height: 2.5rem;
    width: 5rem;
    text-align: center;
    font-weight: 600;
    font-size: 1rem;
    text-transform: uppercase;
    letter-spacing: 2px;
    position: absolute;
    bottom: 0;
    transition: opacity 0.25s;
  }
  .btn-toggle.btn-lg:before {
    content: "Off";
    left: -5rem;
  }
  .btn-toggle.btn-lg:after {
    content: "On";
    right: -5rem;
    opacity: 0.5;
  }
  .btn-toggle.btn-lg > .handle {
    position: absolute;
    top: 0.3125rem;
    left: 0.3125rem;
    width: 1.875rem;
    height: 1.875rem;
    border-radius: 1.875rem;
    background: #fff;
    transition: left 0.25s;
  }
  .btn-toggle.btn-lg.active {
    transition: background-color 0.25s;
  }
  .btn-toggle.btn-lg.active > .handle {
    left: 2.8125rem;
    transition: left 0.25s;
  }
  .btn-toggle.btn-lg.active:before {
    opacity: 0.5;
  }
  .btn-toggle.btn-lg.active:after {
    opacity: 1;
  }
  .btn-toggle.btn-lg.btn-sm:before,
  .btn-toggle.btn-lg.btn-sm:after {
    line-height: 0.5rem;
    color: #fff;
    letter-spacing: 0.75px;
    left: 0.6875rem;
    width: 3.875rem;
  }
  .btn-toggle.btn-lg.btn-sm:before {
    text-align: right;
  }
  .btn-toggle.btn-lg.btn-sm:after {
    text-align: left;
    opacity: 0;
  }
  .btn-toggle.btn-lg.btn-sm.active:before {
    opacity: 0;
  }
  .btn-toggle.btn-lg.btn-sm.active:after {
    opacity: 1;
  }
  .btn-toggle.btn-lg.btn-xs:before,
  .btn-toggle.btn-lg.btn-xs:after {
    display: none;
  }
  .btn-toggle.btn-sm {
    margin: 0 0.5rem;
    padding: 0;
    position: relative;
    border: none;
    height: 1.5rem;
    width: 3rem;
    border-radius: 1.5rem;
  }
  .btn-toggle.btn-sm:focus,
  .btn-toggle.btn-sm.focus,
  .btn-toggle.btn-sm:focus.active,
  .btn-toggle.btn-sm.focus.active {
    outline: none;
  }
  .btn-toggle.btn-sm:before,
  .btn-toggle.btn-sm:after {
    line-height: 1.5rem;
    width: 0.5rem;
    text-align: center;
    font-weight: 600;
    font-size: 0.55rem;
    text-transform: uppercase;
    letter-spacing: 2px;
    position: absolute;
    bottom: 0;
    transition: opacity 0.25s;
  }
  .btn-toggle.btn-sm:before {
    content: "Off";
    left: -0.5rem;
  }
  .btn-toggle.btn-sm:after {
    content: "On";
    right: -0.5rem;
    opacity: 0.5;
  }
  .btn-toggle.btn-sm > .handle {
    position: absolute;
    top: 0.1875rem;
    left: 0.1875rem;
    width: 1.125rem;
    height: 1.125rem;
    border-radius: 1.125rem;
    background: #fff;
    transition: left 0.25s;
  }
  .btn-toggle.btn-sm.active {
    transition: background-color 0.25s;
  }
  .btn-toggle.btn-sm.active > .handle {
    left: 1.6875rem;
    transition: left 0.25s;
  }
  .btn-toggle.btn-sm.active:before {
    opacity: 0.5;
  }
  .btn-toggle.btn-sm.active:after {
    opacity: 1;
  }
  .btn-toggle.btn-sm.btn-sm:before,
  .btn-toggle.btn-sm.btn-sm:after {
    line-height: -0.5rem;
    color: #fff;
    letter-spacing: 0.75px;
    left: 0.4125rem;
    width: 2.325rem;
  }
  .btn-toggle.btn-sm.btn-sm:before {
    text-align: right;
  }
  .btn-toggle.btn-sm.btn-sm:after {
    text-align: left;
    opacity: 0;
  }
  .btn-toggle.btn-sm.btn-sm.active:before {
    opacity: 0;
  }
  .btn-toggle.btn-sm.btn-sm.active:after {
    opacity: 1;
  }
  .btn-toggle.btn-sm.btn-xs:before,
  .btn-toggle.btn-sm.btn-xs:after {
    display: none;
  }
  .btn-toggle.btn-xs {
    margin: 0 0;
    padding: 0;
    position: relative;
    border: none;
    height: 1rem;
    width: 2rem;
    border-radius: 1rem;
  }
  .btn-toggle.btn-xs:focus,
  .btn-toggle.btn-xs.focus,
  .btn-toggle.btn-xs:focus.active,
  .btn-toggle.btn-xs.focus.active {
    outline: none;
  }
  .btn-toggle.btn-xs:before,
  .btn-toggle.btn-xs:after {
    line-height: 1rem;
    width: 0;
    text-align: center;
    font-weight: 600;
    font-size: 0.75rem;
    text-transform: uppercase;
    letter-spacing: 2px;
    position: absolute;
    bottom: 0;
    transition: opacity 0.25s;
  }
  .btn-toggle.btn-xs:before {
    content: "Off";
    left: 0;
  }
  .btn-toggle.btn-xs:after {
    content: "On";
    right: 0;
    opacity: 0.5;
  }
  .btn-toggle.btn-xs > .handle {
    position: absolute;
    top: 0.125rem;
    left: 0.125rem;
    width: 0.75rem;
    height: 0.75rem;
    border-radius: 0.75rem;
    background: #fff;
    transition: left 0.25s;
  }
  .btn-toggle.btn-xs.active {
    transition: background-color 0.25s;
  }
  .btn-toggle.btn-xs.active > .handle {
    left: 1.125rem;
    transition: left 0.25s;
  }
  .btn-toggle.btn-xs.active:before {
    opacity: 0.5;
  }
  .btn-toggle.btn-xs.active:after {
    opacity: 1;
  }
  .btn-toggle.btn-xs.btn-sm:before,
  .btn-toggle.btn-xs.btn-sm:after {
    line-height: -1rem;
    color: #fff;
    letter-spacing: 0.75px;
    left: 0.275rem;
    width: 1.55rem;
  }
  .btn-toggle.btn-xs.btn-sm:before {
    text-align: right;
  }
  .btn-toggle.btn-xs.btn-sm:after {
    text-align: left;
    opacity: 0;
  }
  .btn-toggle.btn-xs.btn-sm.active:before {
    opacity: 0;
  }
  .btn-toggle.btn-xs.btn-sm.active:after {
    opacity: 1;
  }
  .btn-toggle.btn-xs.btn-xs:before,
  .btn-toggle.btn-xs.btn-xs:after {
    display: none;
  }
  .btn-toggle.btn-secondary {
    color: #6b7381;
    background: #bdc1c8;
  }
  .btn-toggle.btn-secondary:before,
  .btn-toggle.btn-secondary:after {
    color: #6b7381;
  }
  .btn-toggle.btn-secondary.active {
    background-color: #ff8300;
  }
  
/* For Mobile View */

.mobile {
    display: inline-block;
}

.desktop {
    display: none;
}

.search-bar {
    min-width: 150px;
    max-width: 200px;
    min-height: 20px;
    max-height: 30px;
}

.__index {
    z-index: 0;
}

.__add_role {
    margin-top: 14px;
    margin-bottom: 20px;
}

.__user_email_box {
    position: relative;
    width: 200px;
    min-height: 38px;
    padding: 5px;
    font-size: small;
    top: 4px;
}

.search-bar-label {
    text-align: center;
    /* min-width: 150px; */
    width: 200px;
    /* min-height: 20px; */
    /* height: 30px; */
}

.__flex_settings_body_container {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center;
}


.__flex_settings_body_filter_container {
    padding: 0 20px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center;
}

.basic-multi-select {
    min-width: 200px;
    max-width: 300px;
    min-height: 20px;
    max-height: 30px;
}

.__paginator_flex_item {
    position: relative;
    top: 24px;
}


@media only screen and (min-width: 768px) {
    /* For desktop: */


    .__user_email_box {
        position: relative;
        width: 230px;
        min-height: 38px;
        padding: 5px;
        font-size: small;
        top: 4px;
    }

    .__add_role {
        margin-top: 25px;
        margin-bottom: 20px;
    }

    .__flex_settings_body_container {
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: row;
                flex-direction: row;
        -webkit-justify-content: center;
                justify-content: center;
    }
    
    .basic-multi-select {
        min-width: 200px;
        max-width: 300px;
        min-height: 20px;
        max-height: 30px;
    }
    
    .search-bar {
        min-width: 200px;
        max-width: 300px;
        min-height: 20px;
        max-height: 30px;
    }
    
    .__flex_settings_body_filter_container {
        padding: 0 20px;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: row;
                flex-direction: row;
        -webkit-justify-content: space-between;
                justify-content: space-between;
    }
    
    .__paginator_flex_item {
        position: relative;
        top: 24px;
    }

    .mobile {
        display: none;
    }

    .desktop {
        display: inline-block;
    }
}



@media only screen and (min-width: 600px) {
    /* For tablets: */
    .mobile {
        display: none;
    }
    .desktop {
        display: inline-block;
    }
}
/* body {
    font-family: 'Roboto', sans-serif;
    background-color: #f5f5f5;

} */


.wrapper {
    display: -webkit-flex;
    display: flex;
}

.Card {
    margin-bottom: 20px;
    width: 100%;
}

/* .wrapper > .Card:first-of-type {
    margin-right: 20px;
} */

.notified--card {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
}

.notified--card .left {
    margin-left: auto;
    margin-right: 10px;
}

.notified--card .right {
    width: 50%;
}

.notified--card .right > div {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
}

.time-pickers {
    padding: 30px !important;
}

.time-pickers > div {
    margin-left: 150px;
}

.contact--card {
    display: -webkit-flex;
    display: flex;
}

.contact--card > div {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    padding: 30px;
    width: 100%;
}

.contact--card .FormControl {
    width: 100%;
}

.contact--card .FormControl label + .MuiInput-formControl {
    margin-top: 25px;
}

.number-wrapper {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-justify-content: center;
            justify-content: center;
}

.number-wrapper .Card {
    width: 290px;
}
.number-wrapper .MuiCollapse-wrapperInner {
    display: -webkit-flex;
    display: flex;
}
.number-wrapper .MuiCollapse-wrapperInner .Card:nth-child(odd) {
    margin-right: 20px;
}

.number-wrapper .Card .MuiFormControlLabel-label {
    font-size: 14px;
}
.btn-default {
    margin-right: 10px !important;
}

.MuiTableContainer-root {
    margin-bottom: 20px;
}

/* .notification_textBox {
    margin: 10px 20px !important;
} */

.notification_label-wrapper {
    height: 50px;
}

.notification_label {
    width: 75px;
    text-align: left;
}

.clock-label {
    margin-top: 20px !important;
    margin-right: 20px !important;
    width: 100px;
}

#demo-simple-select-label {
    font-size: 14px;
}

.selected {
    color: green;
    width: 5px;
    height: 5px;
}

.platform-item-placeholder {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
}

.platform-item-placeholder > img {
    width: 200px;
    height: 70px;
}

.platform-item-placeholder > .item {
    padding: 10px;
}

img.slack {
    height: 45px;
    width: 130px;
}

.item-container-column {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
}

.item-container-row {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
}
.platform-container {
    /* min-height: 60vh; */
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
}

.platform-container-text {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    color: gray;
}

.platform-container-assignment-group {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    padding-top: '20px'
}
.platform-container-jira-priority {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
}
.calling-window-container-table {
    padding: 0px 10px;
}
.calling-window-picker-container {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
}
.calling-window-picker-container-table {
    padding: 10px;
}
/* .container {
    min-height: 55vh;
}
.flex-container {
    display: flex !important;
    flex-direction: row !important;
    justify-content: flex-start !important;
    align-items: flex-end !important;
    padding: 10px !important;
}

.flex-container > .item {
    display: flex !important;
    flex-direction: column;
    justify-content: flex-end !important;
    align-items: flex-start !important;
    margin-left: 5px !important;
} */


.container {
    min-height: 55vh;
}
.flex-container {
    display: -webkit-flex !important;
    display: flex !important;
    -webkit-flex-direction: row !important;
            flex-direction: row !important;
    -webkit-justify-content: flex-start !important;
            justify-content: flex-start !important;
    -webkit-align-items: flex-end !important;
            align-items: flex-end !important;
    padding: 10px !important;
}

.flex-container > .item {
    display: -webkit-flex !important;
    display: flex !important;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: flex-end !important;
            justify-content: flex-end !important;
    -webkit-align-items: flex-start !important;
            align-items: flex-start !important;
    margin-left: 5px !important;
}

.container {
    min-height: 55vh;
}
.flex-container {
    display: -webkit-flex !important;
    display: flex !important;
    -webkit-flex-direction: row !important;
            flex-direction: row !important;
    -webkit-justify-content: flex-start !important;
            justify-content: flex-start !important;
    -webkit-align-items: flex-end !important;
            align-items: flex-end !important;
    padding: 10px !important;
}

.flex-container > .item {
    display: -webkit-flex !important;
    display: flex !important;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: flex-end !important;
            justify-content: flex-end !important;
    -webkit-align-items: flex-start !important;
            align-items: flex-start !important;
    margin-left: 5px !important;
}

/* .container {
    min-height: 55vh;
}
.flex-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
} */

.container {
    min-height: 55vh;
}
.flex-container {
    display: -webkit-flex !important;
    display: flex !important;
    -webkit-flex-direction: row !important;
            flex-direction: row !important;
    -webkit-justify-content: flex-start !important;
            justify-content: flex-start !important;
    -webkit-align-items: flex-end !important;
            align-items: flex-end !important;
    padding: 10px !important;
}

.flex-container > .item {
    display: -webkit-flex !important;
    display: flex !important;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: flex-end !important;
            justify-content: flex-end !important;
    -webkit-align-items: flex-start !important;
            align-items: flex-start !important;
    margin-left: 5px !important;
}

.container {
    min-height: 55vh;
}
.flex-container {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
}

.item {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
}
.daterangepicker .ranges li.active {
    background-color: #08c;
    color: #fff;
    border-radius: 0%;
}

.daterangepicker.ltr.show-ranges.opensright.show-calendar {
    z-index: 9999;
}
.__flex_container {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
}

.__dropdown_index {
    z-index: 0;
}

.__flex_container > .__flex_item {
    font-size: small;
    margin-top: 10px; 
    margin-bottom: 10px;
    padding: 0 10px 0 10px;
}

.__flex_container > .__flex_item.__selector {
    min-width: 200px;
    cursor: pointer;
}

.__flex_item.__selector:hover {
    cursor: pointer;
}

.__flex_header_container {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: space-between;
            justify-content: space-between;
}

.__flex_header_container > .__flex_item {
    /* font-size: small; */
    margin-top: 10px; 
    margin-bottom: 10px;
    padding: 0 10px 0 10px;
}

@media only screen and (min-width: 600px) {
    /* For tablets: */
    .__flex_header_container {
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: row;
                flex-direction: row;
        -webkit-justify-content: space-between;
                justify-content: space-between;
    }
    
    .__flex_header_container > .__flex_item {
        /* font-size: small; */
        margin-top: 10px; 
        margin-bottom: 10px;
        padding: 0 10px 0 10px;
    }

    .__flex_container {
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: column;
                flex-direction: column;
        -webkit-align-items: center;
                align-items: center;
    }
    
    .__flex_container > .__flex_item {
        font-size: small;
        margin-top: 10px; 
        margin-bottom: 10px;
        padding: 0 10px 0 10px;
    }
    
    .__flex_container > .__flex_item.__selector {
        min-width: 200px;
    }
}

@media only screen and (min-width: 768px) {
    /* For desktop: */

    .__flex_header_container {
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: row;
                flex-direction: row;
        -webkit-justify-content: space-between;
                justify-content: space-between;
    }
    
    .__flex_header_container > .__flex_item {
        /* font-size: small; */
        margin-top: 10px; 
        margin-bottom: 10px;
        padding: 0 10px 0 10px;
    }

.__flex_container {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: space-between;
            justify-content: space-between;
}

.__flex_container > .__flex_item {
    font-size: small;
    margin-top: 10px; 
    margin-bottom: 10px;
    padding: 0 10px 0 10px;
}

.__flex_container > .__flex_item.__selector {
    min-width: 200px;
}

}

.__paginator_item {
    padding: 0px 7px;
}

.__active_page_link {
    text-decoration: none;
    color: white;
}

.__paginator_item.active:hover > .__active_page_link,
.__paginator_item.active > .__active_page_link:hover {
    background-color: white;
    color: #007bff;
}

.__paginator_item.active > .__active_page_link {
    color: white;
    background-color: #007bff;
}
/* .__active_page_link:hover{
    color: #007bff;
    border-color: #007bff;
} */

.__paginator_item {
    text-decoration: none;
}

.__paginator_item > a:hover {
    text-decoration: none;
}

.active{
    text-decoration: none;
    background-color: #007bff;
    border-radius: 50%;
    color: white;
}

.active:hover {
    text-decoration: none;
    background-color: white;
    color: #007bff;
    border-radius: 50%;
    border-color: #007bff;
}

.__active_page_link:hover {
    text-decoration: none;
    background-color: white;
    color: #007bff;
    border-color: #007bff;
}


@media only screen and (max-width: 768px) {
    /* For mobile phones: */
    
}
.__flex_container_dcb {
    display: -webkit-flex;
    display: flex;
    max-width: 97vw;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: space-between;
            justify-content: space-between;
}
.__flex_item_dcb{
    padding: 10px;
    
}

.__flex {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: space-between;
            justify-content: space-between;
}

.__flex > .__item {
    padding: 10px;
}

.table.review.large.screen {
    display: inline-table;
    border: 1;
    width: 95vw;
    margin: 0px 10px;
}

.table.review.small.screen {
    display: none;
}

@media only screen and (max-width: 768px) {
    /* For mobile phones: */

    .table.review.large.screen {
        display: none;
    }

    .table.review.small.screen {
        display: inline-table;
    }

    /* .table.review.small.screen > tfoot > tr > td > div.__flex.__footer {
        flex-direction: column;
        justify-items: center;
    } */

    .table.review.small.screen > tfoot > tr > td > div.__flex.__footer {
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: column;
                flex-direction: column;
        -webkit-justify-content: center;
                justify-content: center;
        -webkit-align-items: center;
                align-items: center;
    }
    
    .table-review-content > tbody > tr > td {
        border: 0;
    }
}
.root-modal {
  display: block !important;
}

.root-modal > .root-modal-body.background {
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.4s ease-in-out;
}

.body {
  padding: 10px;
}

textarea.form-control.feedback {
  min-height: 200px;
  overflow-y: hidden;
}

.mstrue {
  opacity: 1;
  pointer-events: showing;
}

.ReactModal__Overlay.ReactModal__Overlay--after-open.modal-overlay {
  z-index: 9999;
}

.modal-content {
  position: fixed;
  top: 50%;
  left: 50%;
  min-height: 350px;
  width: 70vw;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  background: white;
  overflow-y: auto;
  border-radius: 0;
  outline: none;
  border: 0 solid inherit;
  box-shadow: inherit;
}

.status-bar {
  position: relative;
  bottom: 0;
  left: 0;
}

.btn.exit {
  background-color: transparent;
  width: 15px;
  height: 15px;
  color: white;
}

.exit {
  color: red;
  background-color: white;
  font-size: 15px;
}

.footer {
  /* position: absolute;
    bottom: 0;
    right: 0;
    left: 0; */
  padding: 10px;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
}

.dc-r-flex-container {
  display: -webkit-flex !important;
  display: flex !important;
  -webkit-justify-content: space-between !important;
          justify-content: space-between !important;
}

.dc-r-flex-container-end {
  display: -webkit-flex !important;
  display: flex !important;
  -webkit-justify-content: flex-end !important;
          justify-content: flex-end !important;
}

.dc-r-flex-container-start {
  display: -webkit-flex !important;
  display: flex !important;
  -webkit-justify-content: flex-start !important;
          justify-content: flex-start !important;
}

.dc-r-flex-container-end > .item {
}

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 0.4s ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

.btn {
  background-color: transparent;
  border-radius: 0;
  border-color: transparent;
  color: gray;
  font-size: small;
}

.root-modal {
  display: block !important;
}

.root-modal > .root-modal-body.background {
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.4s ease-in-out;
}

.body {
  padding: 10px;
}

textarea.form-control.feedback {
  min-height: 200px;
  overflow-y: hidden;
}

.mstrue {
  opacity: 1;
  pointer-events: showing;
}

.ReactModal__Overlay.ReactModal__Overlay--after-open.modal-overlay {
  z-index: 9999;
}

.modal-content {
  position: fixed;
  top: 50%;
  left: 50%;
  min-height: 350px;
  width: 70vw;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  background: white;
  overflow-y: auto;
  border-radius: 0;
  outline: none;
  border: 0 solid inherit;
  box-shadow: inherit;
}

.status-bar {
  position: relative;
  bottom: 0;
  left: 0;
}

.btn.exit {
  background-color: transparent;
  width: 15px;
  height: 15px;
  color: white;
}

.exit {
  color: red;
  background-color: white;
  font-size: 15px;
}

.footer {
  /* position: absolute;
      bottom: 0;
      right: 0;
      left: 0; */
  padding: 10px;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
}

.dc-r-flex-container {
  display: -webkit-flex !important;
  display: flex !important;
  -webkit-justify-content: space-between !important;
          justify-content: space-between !important;
}

.dc-r-flex-container-end {
  display: -webkit-flex !important;
  display: flex !important;
  -webkit-justify-content: flex-end !important;
          justify-content: flex-end !important;
}

.dc-r-flex-container-start {
  display: -webkit-flex !important;
  display: flex !important;
  -webkit-justify-content: flex-start !important;
          justify-content: flex-start !important;
}

.dc-r-flex-container-end > .item {
}

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 0.4s ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

.responseScroller {
  width: 300px;
  height: 100px;
  overflow-y: scroll;
  scrollbar-width: thin;
}

.btn {
  background-color: transparent;
  border-radius: 0;
  border-color: transparent;
  color: gray;
  font-size: small;
}
.responseDropdown {
  left: 20;
  width: auto;
  z-index: 2;
  border: 1px solid rgba(0, 0, 0, 0.04);
  box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.14);
}

.responseButton {
  position: relative;
  display: inline-block;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
a {
  padding: 8px 12px;
}

li:hover {
  background-color: rgba(0, 0, 0, 0.14);
  cursor: pointer;
}

.__flex_container {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
}

.__dropdown_index {
    z-index: 0;
}

.__flex_container > .__flex_item {
    font-size: small;
    margin-top: 10px; 
    margin-bottom: 10px;
    padding: 0 10px 0 10px;
}

.__flex_container > .__flex_item.__selector {
    min-width: 200px;
    cursor: pointer;
}

.__flex_item.__selector:hover {
    cursor: pointer;
}

.__flex_header_container {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: space-between;
            justify-content: space-between;
}

.__flex_header_container > .__flex_item {
    /* font-size: small; */
    margin-top: 10px; 
    margin-bottom: 10px;
    padding: 0 10px 0 10px;
}

@media only screen and (min-width: 600px) {
    /* For tablets: */
    .__flex_header_container {
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: row;
                flex-direction: row;
        -webkit-justify-content: space-between;
                justify-content: space-between;
    }
    
    .__flex_header_container > .__flex_item {
        /* font-size: small; */
        margin-top: 10px; 
        margin-bottom: 10px;
        padding: 0 10px 0 10px;
    }

    .__flex_container {
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: column;
                flex-direction: column;
        -webkit-align-items: center;
                align-items: center;
    }
    
    .__flex_container > .__flex_item {
        font-size: small;
        margin-top: 10px; 
        margin-bottom: 10px;
        padding: 0 10px 0 10px;
    }
    
    .__flex_container > .__flex_item.__selector {
        min-width: 200px;
    }
}

@media only screen and (min-width: 768px) {
    /* For desktop: */

    .__flex_header_container {
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: row;
                flex-direction: row;
        -webkit-justify-content: space-between;
                justify-content: space-between;
    }
    
    .__flex_header_container > .__flex_item {
        /* font-size: small; */
        margin-top: 10px; 
        margin-bottom: 10px;
        padding: 0 10px 0 10px;
    }

.__flex_container {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: space-between;
            justify-content: space-between;
}

.__flex_container > .__flex_item {
    font-size: small;
    margin-top: 10px; 
    margin-bottom: 10px;
    padding: 0 10px 0 10px;
}

.__flex_container > .__flex_item.__selector {
    min-width: 200px;
}
}

