.basic__footer {
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
}

.basic__footer > p {
    text-align: center;
    color: gray;
    font-size: small;
}