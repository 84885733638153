.landing__page {
    display:flex;
    min-height: 83vh;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.greeting__user__label {
    text-transform: capitalize;
}