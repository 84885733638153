/* .container {
    min-height: 55vh;
}
.flex-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
} */

.container {
    min-height: 55vh;
}
.flex-container {
    display: flex !important;
    flex-direction: row !important;
    justify-content: flex-start !important;
    align-items: flex-end !important;
    padding: 10px !important;
}

.flex-container > .item {
    display: flex !important;
    flex-direction: column;
    justify-content: flex-end !important;
    align-items: flex-start !important;
    margin-left: 5px !important;
}
