/* For Mobile View */

.mobile {
    display: inline-block;
}

.desktop {
    display: none;
}

.search-bar {
    min-width: 150px;
    max-width: 200px;
    min-height: 20px;
    max-height: 30px;
}

.__index {
    z-index: 0;
}

.__add_role {
    margin-top: 14px;
    margin-bottom: 20px;
}

.__user_email_box {
    position: relative;
    width: 200px;
    min-height: 38px;
    padding: 5px;
    font-size: small;
    top: 4px;
}

.search-bar-label {
    text-align: center;
    /* min-width: 150px; */
    width: 200px;
    /* min-height: 20px; */
    /* height: 30px; */
}

.__flex_settings_body_container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}


.__flex_settings_body_filter_container {
    padding: 0 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.basic-multi-select {
    min-width: 200px;
    max-width: 300px;
    min-height: 20px;
    max-height: 30px;
}

.__paginator_flex_item {
    position: relative;
    top: 24px;
}


@media only screen and (min-width: 768px) {
    /* For desktop: */


    .__user_email_box {
        position: relative;
        width: 230px;
        min-height: 38px;
        padding: 5px;
        font-size: small;
        top: 4px;
    }

    .__add_role {
        margin-top: 25px;
        margin-bottom: 20px;
    }

    .__flex_settings_body_container {
        display: flex;
        flex-direction: row;
        justify-content: center;
    }
    
    .basic-multi-select {
        min-width: 200px;
        max-width: 300px;
        min-height: 20px;
        max-height: 30px;
    }
    
    .search-bar {
        min-width: 200px;
        max-width: 300px;
        min-height: 20px;
        max-height: 30px;
    }
    
    .__flex_settings_body_filter_container {
        padding: 0 20px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
    
    .__paginator_flex_item {
        position: relative;
        top: 24px;
    }

    .mobile {
        display: none;
    }

    .desktop {
        display: inline-block;
    }
}



@media only screen and (min-width: 600px) {
    /* For tablets: */
    .mobile {
        display: none;
    }
    .desktop {
        display: inline-block;
    }
}