.__flex_container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.__dropdown_index {
    z-index: 0;
}

.__flex_container > .__flex_item {
    font-size: small;
    margin-top: 10px; 
    margin-bottom: 10px;
    padding: 0 10px 0 10px;
}

.__flex_container > .__flex_item.__selector {
    min-width: 200px;
    cursor: pointer;
}

.__flex_item.__selector:hover {
    cursor: pointer;
}

.__flex_header_container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.__flex_header_container > .__flex_item {
    /* font-size: small; */
    margin-top: 10px; 
    margin-bottom: 10px;
    padding: 0 10px 0 10px;
}

@media only screen and (min-width: 600px) {
    /* For tablets: */
    .__flex_header_container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
    
    .__flex_header_container > .__flex_item {
        /* font-size: small; */
        margin-top: 10px; 
        margin-bottom: 10px;
        padding: 0 10px 0 10px;
    }

    .__flex_container {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    
    .__flex_container > .__flex_item {
        font-size: small;
        margin-top: 10px; 
        margin-bottom: 10px;
        padding: 0 10px 0 10px;
    }
    
    .__flex_container > .__flex_item.__selector {
        min-width: 200px;
    }
}

@media only screen and (min-width: 768px) {
    /* For desktop: */

    .__flex_header_container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
    
    .__flex_header_container > .__flex_item {
        /* font-size: small; */
        margin-top: 10px; 
        margin-bottom: 10px;
        padding: 0 10px 0 10px;
    }

.__flex_container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.__flex_container > .__flex_item {
    font-size: small;
    margin-top: 10px; 
    margin-bottom: 10px;
    padding: 0 10px 0 10px;
}

.__flex_container > .__flex_item.__selector {
    min-width: 200px;
}
}
