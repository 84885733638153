.container {
    min-height: 55vh;
}
.flex-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}